import PropTypes from 'prop-types'

import { GlobalContainer, Main } from './style'

const NakedLayout = ({ children }) => (
  <GlobalContainer>
    <Main>{children}</Main>
  </GlobalContainer>
)

NakedLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NakedLayout
