export const ActionType = {
  SUCCESS: 'success',
  LOADING: 'loading',
  FAILED: 'failed',
  RESET: 'reset',
}

export const INITIAL_ACTION_STATE = {
  status: null,
  error: null,
}
