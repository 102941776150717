import { Color } from '../utils/constants'
import { ThemeProvider } from 'styled-components/macro'

const defaultTheme = {
  colorPrimary: Color.DEEP_SEA,
  fontSizeM: '1rem',

  fontWeightNormal: '300',
  fontWeightBold: '700',
}

const TP = ({ children }) => (
  <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>
)

export default TP
